<template lang="pug">
#formCreateSeller
  validation-observer(ref="observer" v-slot="{ passes }")
    form(@submit.stop.prevent="passes(onSubmitSeller)")
      b-row
        b-col(cols="12")
          validation-provider(name="Nombre y Apellido" rules="required" v-slot="validationContext")
            b-form-group(label="Nombre y Apellido")
              b-form-input(
                v-model="seller.full_name"
                type="text"
                name="seller_full_name"
                aria-describedby="input-seller-fullname"
              )
              b-form-invalid-feedback#input-seller-fullname {{ validationContext.errors[0] }}
        b-col(cols="6")
          validation-provider(name="Teléfono 1" rules="required" v-slot="validationContext")
            b-form-group(label="Teléfono 1")
              b-form-input(
                v-model="seller.phone_1"
                type="tel"
                name="seller_phone_1"
                :disabled="seller.full_name ? false : true"
                aria-describedby="input-seller-phone-1"
              )
              b-form-invalid-feedback#input-seller-phone {{ validationContext.errors[0] }}
        b-col(cols="6")
          validation-provider(name="Teléfono 2" v-slot="validationContext")
            b-form-group(label="Teléfono 2")
              b-form-input(
                v-model="seller.phone_2"
                type="tel"
                name="seller_phone_2"
                :disabled="seller.phone_1 ? false : true"
                aria-describedby="input-seller-phone-2"
              )
              b-form-invalid-feedback#input-seller-phone {{ validationContext.errors[0] }}
        b-col(cols="12")
          validation-provider(name="Correo" rules="required" v-slot="validationContext")
            b-form-group(label="Correo")
              b-form-input(
                v-model="seller.email"
                type="email"
                name="seller_email"
                :disabled="seller.phone_1 ? false : true"
                aria-describedby="input-seller-email"
              )
              b-form-invalid-feedback#input-seller-email {{ validationContext.errors[0] }}
        b-col(cols="12")
          b-button(variant="outline-success" type="submit") {{ buttonText }}
        b-overlay#loading(:show="show" opacity="0.7" no-wrap="")
            template(v-slot:overlay="")
              .text-center
                b-spinner(variant="info")
                span.d-block.text-info Espere un momento...
</template>
<script>
import { required, email } from "vuelidate/lib/validators";
export default {
  name: 'FormCreateSeller',
  data() {
    return {
      product: 0,
      seller: {
        full_name: null,
        phone_1: null,
        phone_2: null,
        email: null
      },
      buttonText: "Guardar cliente",
      show: false,
    }
  },
  mounted() {
    this.product = this.$parent.$parent.$parent.product
  },
  methods: {
    async onSubmitSeller() {
      this.loading();
      if (this.$invalid) {
        this.$bvToast.toast("Campos requeridos o Inválidos en el formulario.", {
          title: "Campos requeridos",
          variant: "danger",
          toaster:"b-toaster-bottom-right",
          solid: true
        });
      } else {
        if (this.seller) {
          try {
            this.butonDisabled = true;
            this.buttonText = "Cargando...";
            const { data } = await this.$axios.post("/api/v1/seller", this.seller)
            const { id, full_name, phone_1, phone_2, email } = data.data
            const seller =  {
              id: id,
              full_name: full_name,
              phone_1: phone_1,
              phone_2: phone_2,
              email: email
            }
            this.createSeller(seller);
            this.show = false
            this.buttonText = "Guardar cliente"
            this.butonDisabled = false
            this.$swal({
              title: "Cliente registrado",
              text: "Registro de cliente realizado con éxito.",
              icon: "success",
              timer: 2000,
              confirmButtonClass: "btn btn-outline-primary",
              confirmButtonText: "Ok",
              buttonsStyling: false
            })
          } catch (error) {
            this.butonDisabled = false
            this.buttonText = "Guardar cliente"
            this.show = false
            this.$swal({
              title: "Registro de cliente!",
              text: "Error al realizar el registro del cliente.",
              icon: "error",
              confirmButtonClass: "btn btn-outline-danger",
              confirmButtonText: "Ok",
              buttonsStyling: false
            });
            console.error(error)
          }
        } else {
          this.butonDisabled = false;
          this.buttonText = "Guardar cliente";
          this.$swal({
            title: "Completar Formulario!",
            text: "Completar todos los campos requeridos",
            icon: "error",
            confirmButtonClass: "btn btn-outline-danger",
            confirmButtonText: "Ok",
            buttonsStyling: false
          });
        }
      }
    },
    async createSeller(seller) {
      if(seller) {
        this.$emit("createSeller", seller)
        this.product = 0
        this.seller.full_name = null
        this.seller.phone_1 = null
        this.seller.phone_2 = null
        this.seller.email = null
      }
    },
    loading(){
      this.show = true;
      const height = window.outerHeight;
      window.scrollTo({top:(height/2)});
    }
  }
}
</script>
<style lang="scss">
  #formCreateSeller {
    padding-bottom: 20px;
  }
</style>