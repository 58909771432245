<template lang="pug">
.radio-btn-group-icon.text-center
  .radio(v-for="option in options")
    input(type="radio", :name="name", :value="option.value", @input="handleInput", :id="option.value", :disabled="disabled", :checked="checked === option.value ? 'true': false")
    label(:for="option.value")
      span.d-block(v-if="option.icon")
        span( v-if="option.icon === 'icon-awd'" :class="option.icon")
          span(class="path1")
          span(class="path2")
          span(class="path3")
          span(class="path4")
          span(class="path5")
        i( v-else :class="option.icon")
    span.label {{option.name}}
</template>
<script>
export default {
  name: "RadioButtonIcon",
  props: {
    value: {
      type: String,
      default: function() {
        return ""
      }
    },
    options: {
      type: Array,
      default: function() {
        return [];
      }
    },
    name: {
      type: String,
      default: ""
    },
		disabled: {
      type: Boolean,
			default: function () {
				return false;
      }
		}
  },
  data() {
    return {
      checked: this.value
    };
  },
  mounted: function(){
  },
  methods: {
    handleInput: function(e) {
      this.checked = e.target.value;
      this.$emit("input", this.checked);
    }
  }
};
</script>
<style lang="scss">
@import "src/assets/scss/variables";

.radio-btn-group-icon {
  display: flex;
  flex: auto;
  .radio {
    margin: 0 0.25rem;
    flex-grow: 1;
    label {
      background: #f7f7f7;
      border: 1px solid #ddd;
      font-size: 0.7rem;
      padding: 0.5rem 0.8rem;
      border-radius: 5px;
      cursor: pointer;
      display: block;
      color: #444;
      transition: box-shadow 400ms ease;
      margin-bottom: .1rem;
      span {
        i,
				span{
          font-size: 2.4rem;
        }
      }
      &:hover {
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1),
          0 2px 10px 0 rgba(0, 0, 0, 0.12);
      }
    }
  }
  input[type="radio"] {
    display: none;
		&:disabled + label{
			background-color: $disabled;
			&:hover{
				box-shadow: none;
			}
		}
		&:checked + label {
			background: $primary;
			color: #fff;
			border-color: $primary;

		}
  }
	span.label{
		color: #777;
		font-size: .65rem;
		font-weight: 600;
		text-transform: uppercase;
	}
}
</style>
